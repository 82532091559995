body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

/* Black navbar */
.vcs-tyrefinder---components-LinkBar----LinkBar-module---link-bar {
    top: 0 !important;
}

/* White navbar */
.vcs-tyrefinder---components-NavBar----NavBar-module---container {
    top: 50px !important;
}

/* Dropdown menu */
.vcs-tyrefinder---components-LinkBar----LinkBar-module---toggleOpen {
    top: 50px !important;
}
